import { MenuItem } from 'primeng/api';
import { TreeItemType } from '../modules/tree';

/**
 * Утилита для преобразования данных элемента Tree в данные для Breadcrumb
 * @param treeItem данные элемента Tree
 */
export const mapTreeItemToBreadcrumbItems = (treeItem?: TreeItemType) => {
  let breadcrumbItems: MenuItem[] = [];

  if (treeItem) {
    breadcrumbItems.push({
      label: treeItem.label,
    });

    breadcrumbItems = mapTreeItemToBreadcrumbItems(treeItem.parent).concat(breadcrumbItems);
  }

  return breadcrumbItems;
};
