import { TreeItemType } from '../modules/tree';

/**
 * Утилита для получения роута для элемента Tree
 * @param treeItem данные элемента Tree
 */
export const mapTreeItemToRouteItems = (treeItem?: TreeItemType) => {
  let routeItems: string[] = [];

  if (treeItem) {
    if (treeItem.selectable) {
      routeItems.push(treeItem.type ?? '');
      routeItems.push(treeItem.key ?? '');
    }

    routeItems = mapTreeItemToRouteItems(treeItem.parent).concat(routeItems);
  }

  return routeItems;
};
