import { Dictionary } from '@src/app/constants';

/**
 * Утилита, которая нормализует данные справочника, который хранится на фронте
 *
 * @param id id из справочника
 */
export const normalizeDictionaryValue = (id: number | string, dictionary: Dictionary[]): string => {
  return dictionary.find(item => item.id === id)?.name ?? '';
};
