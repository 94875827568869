/**
 * Утилита, которая преобразует значение 'null' в 'undefined'.
 *
 * @param value значение
 */
export const fixNullToUndefined = <T>(value?: T | null): T | undefined => {
  if (value === undefined || value === null) {
    return undefined;
  }

  return value;
};
