import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const jsonValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null; // Если поле пустое, считаем, что это валидно
    }

    try {
      JSON.parse(value); // Пытаемся распарсить строку как JSON
      return null; // Если парсинг успешный, то возвращаем null (т.е. валидно)
    } catch {
      return { invalidJson: true }; // Если парсинг не удался, возвращаем ошибку
    }
  };
};
