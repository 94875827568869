/**
 * Функция, которая делает копию объекта без ссылки
 * @param value
 */
// TODO: заменить any на дженерик
export const getObjectCopy = (value: any): any => {
  if (!value) {
    return;
  }

  return JSON.parse(JSON.stringify(value));
};
