import { Injectable } from '@angular/core';

import { StorageKeys } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  setItem(key: StorageKeys, value: string): void {
    try {
      window.localStorage.setItem(key, value);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  getItem(key: StorageKeys): string | null {
    try {
      return window.localStorage.getItem(key);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      return null;
    }
  }

  removeItem(key: StorageKeys): void {
    try {
      window.localStorage.removeItem(key);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
}
